import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import langSk from 'quasar/lang/sk'
import Dialog from 'quasar/src/plugins/Dialog.js';

// To be used on app.use(Quasar, { ... })
export default {
    config: {},
    plugins: {
        Dialog
    },
    lang: langSk
}