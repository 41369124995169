import { createStore } from 'vuex'

export default createStore({
    state: {
        firma_id: null,
        firma_nazov: null,
    },
    getters: {
        firma_id(state) {
            if (state.firma_id === null && localStorage.firma_id) {
                state.firma_id = parseInt(localStorage.firma_id)
            }
            return state.firma_id
        },
        firma_nazov(state) {
            if (state.firma_nazov === null && localStorage.firma_nazov) {
                state.firma_nazov = localStorage.firma_nazov
            }
            return state.firma_nazov
        }
    },
    mutations: {
        saveFirma(state, objFirma) {
            if (objFirma.id !== null) {
                localStorage.setItem('firma_id', objFirma.id)
                localStorage.setItem('firma_nazov', objFirma.nazov)
                state.firma_id = objFirma.id
                state.firma_nazov = objFirma.nazov
            } else {
                localStorage.removeItem('firma_id')
                localStorage.removeItem('firma_nazov')
                state.firma_id = null
                state.firma_nazov = null
            }
        }
    },
    actions: {},
    modules: {}
})