<template>
  <q-layout view="hHh lpR fFf">
    <q-header bordered class="bg-primary text-white">
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="toggleMenu" />

        {{ currentMenu?.label }}

        <q-select
          v-show="canChooseCompany"
          dark
          dense
          standout
          v-model="firma"
          :options="zoznamFiriem"
          option-value="id"
          option-label="nazov"
          behavior="menu"
          label="Firma"
          class="q-ml-md"
          map-options
          @update:model-value="zmenFirmu"
        >
        </q-select>

        <q-space />
        <div><q-avatar color="secondary" text-color="white">MD</q-avatar></div>
      </q-toolbar>
    </q-header>

    <q-drawer
      show-if-above
      v-model="menuOpen"
      side="left"
      bordered
      :mini="miniState"
      @mouseover="miniState = false"
      @mouseout="miniState = true"
      mini-to-overlay
    >
      <q-scroll-area class="fit">
        <q-list v-for="(menuItem, index) in menuButtons" :key="index">
          <q-item
            clickable
            :active="
              menuItem.slug === currentMenu?.slug ||
              currentMenu?.active === menuItem.slug
            "
            :to="{name: menuItem.slug}"
          >
            <q-item-section avatar>
              <q-icon :name="menuItem.icon" />
            </q-item-section>
            <q-item-section>
              {{ menuItem.label }}
            </q-item-section>
          </q-item>
          <q-separator :key="'sep' + index" v-if="menuItem.separator" />
        </q-list>
      </q-scroll-area>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script setup>
  import {ref, computed, reactive} from "vue";
  import {useRoute} from "vue-router";
  import {useStore} from "vuex-composition-helpers";

  const store = useStore();

  // otvaranie/zatvaranie menu
  const menuOpen = ref(true);
  function toggleMenu() {
    menuOpen.value = !menuOpen.value;
  }

  const menuList = reactive([
    {
      icon: "dashboard",
      slug: "home",
      label: "Prehľad",
      separator: true,
      visible: true,
    },
    {
      icon: "group",
      slug: "dodavatelia",
      label: "Dodávatelia",
      separator: false,
      visible: true,
    },
    {
      icon: "group",
      slug: "dodavatel",
      label: "Dodávateľ",
      active: "dodavatelia",
      separator: false,
      visible: false,
    },
    {
      icon: "group",
      slug: "dodavatelia-add",
      label: "Pridať dodávateľa",
      active: "dodavatelia",
      separator: false,
      visible: false,
    },
    {
      icon: "assignment",
      slug: "projekty",
      label: "Projekty",
      separator: false,
      visible: true,
    },
    {
      icon: "assignment",
      slug: "projekt",
      label: "Projekt",
      active: "projekty",
      separator: false,
      visible: false,
    },
    {
      icon: "receipt",
      slug: "faktury",
      label: "Faktúry",
      separator: true,
      visible: true,
    },
    {
      icon: "settings",
      slug: "nastavenia",
      label: "Nastavenia",
      separator: false,
      visible: true,
    },
    {
      icon: "groups",
      slug: "zamestnanci",
      label: "Zamestnanci",
      separator: true,
      visible: true,
    },
    {
      icon: "logout",
      slug: "odhlasenie",
      label: "Odhlásenie",
      separator: false,
      visible: true,
    },
  ]);

  function zmenFirmu() {
    console.log(firma);
    store.commit("saveFirma", firma.value);
  }

  const miniState = ref(true);

  const firma = ref(store.getters.firma_id);

  const zoznamFiriem = [
    {nazov: "IT LEARNING SLOVAKIA, s.r.o.", id: 1},
    {nazov: "Firma 2, s.r.o.", id: 2},
    {nazov: "Alfa, s.r.o.", id: 3},
    {nazov: "Gabčíkovo Business, a.s.", id: 4},
  ];

  const canChooseCompany = ref(true);

  const route = useRoute();

  const currentMenu = computed(() => {
    const current = menuList.filter((row) => {
      return row.slug === route.name;
    })[0];
    document.title = `EDO - ${current.label}`;
    return current;
  });

  const menuButtons = computed(() => {
    return menuList.filter(function (item) {
      if (item.visible) return item;
    });
  });
</script>

<style>
  * {
    text-transform: none !important;
  }
</style>
