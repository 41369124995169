import { createRouter, createWebHistory } from 'vue-router'


const routes = [{
        path: '/',
        name: 'home',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/dodavatelia/list',
        name: 'dodavatelia',
        component: () =>
            import ( /* webpackChunkName: "dodavatelia-list" */ '../views/Dodavatelia/DodavateliaList.vue')
    },
    {
        path: '/dodavatel/:id',
        name: 'dodavatel',
        component: () =>
            import ( /* webpackChunkName: "dodavatel" */ '../views/Dodavatelia/DodavatelDetail.vue')
    },
    {
        path: '/dodavatelia/add',
        name: 'dodavatelia-add',
        component: () =>
            import ( /* webpackChunkName: "dodavatelia-form" */ '../views/Dodavatelia/DodavateliaForm.vue')
    },
    {
        path: '/projekty',
        name: 'projekty',
        component: () =>
            import ( /* webpackChunkName: "projekty" */ '../views/Projekty/ProjektyList.vue')
    },
    {
        path: '/projekt/:id',
        name: 'projekt',
        component: () =>
            import ( /* webpackChunkName: "projekt" */ '../views/Projekty/ProjektDetail.vue')
    },
    {
        path: '/faktury',
        name: 'faktury',
        component: () =>
            import ( /* webpackChunkName: "faktury" */ '../views/Faktury/FakturyList.vue')
    },
    {
        path: '/nastavenia',
        name: 'nastavenia',
        component: () =>
            import ( /* webpackChunkName: "nastavenia" */ '../views/Nastavenia/NastaveniaList.vue')
    },
    {
        path: '/zamestnanci',
        name: 'zamestnanci',
        component: () =>
            import ( /* webpackChunkName: "zamestnanci" */ '../views/Zamestnanci/ZamestnanciList.vue')
    },
    {
        path: '/zamestnanec/:id',
        name: 'zamestnanec',
        component: () =>
            import ( /* webpackChunkName: "zamestnanec" */ '../views/Zamestnanci/ZamestnanecDetail.vue')
    },
    {
        path: '/odhlasenie',
        name: 'odhlasenie',
        component: () =>
            import ( /* webpackChunkName: "odhlasenie" */ '../views/Odhlasenie.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router